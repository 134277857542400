nav.top-bar {
	padding: 0 2rem;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 999;

	.menu:not(.submenu) {
		li {
			&.menu-logo {
				padding: 0;

				a {
					padding: 0;

					img {
						height: ($topbar-height * .7);
					}
				}
			}

			&.menu-text {
				padding: 0;

				a {
					padding: 0 1rem;
					color: $white;
					font-weight: normal;
				}
			}

			&.menu-nav {
				> a {
					color: $white;
					padding: 0 3rem 0 1rem;
					position: relative;
					line-height: $topbar-height;
				}

				i.wsp {
					position: absolute;
					line-height: $topbar-height;
					right: 0;
					color: $main-yellow;
					font: {
						size: 1.75rem;
					}
				}

				&:hover {
					color: $main-yellow;
				}

				&.is-active {
					> a {
						color: $main-yellow;
					}
				}
			}
		}
	}

	.submenu {
		text-align: center;
		padding: 1rem 0;
		min-width: 100px;

		li {
		}

		a {
			display: block;

			&:hover {
				background: darken($light-gray, 10%);
			}
		}
	}

	@include breakpoint(small only) {
		background: $light-gray;
		position: static;
		padding: 0;

		ul.menu {
			background: $light-gray;

			&.main {
				li {
					position: relative;

					&.is-drilldown-submenu-parent {
						> a {
							padding: 1.125rem 3.5rem;

							&::after {
								font-family: 'mcfletcher-wsp';
								content: $wsp-chevron-right;
								position: absolute;
								right: 1rem;
							}
						}

						.js-drilldown-back {
							a {
								padding: 1.125rem 1.125rem 1.125rem 3rem;

								&::before {
									font-family: 'mcfletcher-wsp';
									content: $wsp-chevron-left;
									position: absolute;
									left: 1rem;
								}
							}
						}
					}
				}

				a {
					padding: 1.125rem 1.125rem 1.125rem 3.5rem;

					&:hover {
						background: $main-blue;
						color: $white;

						i, &::after, &::before {
							color: $main-yellow;
						}
					}
				}

				i.wsp {
					margin-right: 1rem;
					font-size: 1.25rem;
					position: absolute;
					left: 1rem;
					top: 50%;
					transform: translateY(-50%);
				}

				.submenu {
					min-width: 100%;
					padding: 0;
					text-align: left;

					&.is-drilldown-submenu {
						box-shadow: 0px 5px 5px 0px rgba($medium-gray,1);
					}

					a {
						padding: 1.125rem;
					}

					.is-submenu-item {
						a {
							padding-left: 3.5rem;
						}
					}
				}
			}
		}
	}
}
nav.title-bar {
	background: $main-blue;
	padding: 1rem .5rem 1rem 1rem;

	img {
		height: rem-calc(40);
	}

	.title-bar-right {
		@include flex;
		justify-content: flex-end;
	}

	button.navicon {
		cursor: pointer;
		align-self: center;

		span {
			display: block;
			width: rem-calc(35);
			height: 3px;
			background: $main-yellow;
			position: relative;
			transition: .25s;

			&:nth-child(1) {
				margin-bottom: 9px;
				top: 0;
			}

			&:nth-child(3) {
				margin-top: 9px;
				bottom: 0;
			}
		}

		&.open {
			span {
				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(1) {
					transform: rotate(45deg);
					top: 12px;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					bottom: 12px;
				}
			}
		}
	}
}

section.sidebar {
	background: $light-gray;
	height: calc(100vh - #{$topbar-height} - #{$footer-height});
	position: fixed;
	overflow: scroll;
	width: $sidebar-width;
	z-index: 2;
	direction: rtl;

	ul.side-menu {
		list-style: none;
		margin: 0;
		text-align: left;

		li {
			position: relative;

			a {
				padding: 1.5rem 1rem 1.5rem 2rem;
				display: block;
				width: 100%;
				cursor: default;

				&[href] {
					cursor: initial;
				}
			}

			i {
				width: rem-calc(24);
				height: rem-calc(24);
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 2rem;
				font: {
					size: 1.25rem;
				}
			}

			&.icon {
				a {
					padding: 1.5rem 1rem 1.5rem 4rem;
				}
			}

			&:hover,
			&.active {
				a[href] {
					background: $main-yellow;
					color: $main-blue;
					cursor: pointer;
				}
			}

			&.sub-item {
				background: $medium-gray;

				.submenu {
					margin: 0;

					li {
						> a {
							background: $medium-gray;
							font-size: .75rem;
							padding: .5rem 2.95rem .5rem 2rem;
							color: $alt-blue;
						}

						&.active,
						&:hover {
							a {
								background: darken($medium-gray, 3%);
								color: $main-blue;
							}
						}
					}

				}

				&.is-accordion-submenu-parent {
					position: relative;

					>a::after {
						border-color: $main-blue transparent transparent;
					}
				}

				> a {
					font-size: .875rem;
					color: $alt-blue;
					padding: 1rem 1rem 1rem 2rem;

					& [href] {
						color: $alt-blue;

						&:hover {
							background: darken($medium-gray, 3%);
						}
					}
				}

				// Active sub items
				&:hover,
				&.active {
					> a[href] {
						background: darken($medium-gray, 3%);
					}
				}

				&.progress {
					> a {
						padding: 1rem 1rem 1rem 4rem;

						// Disc/Circle
						&::after {
							content: '';
							position: absolute;
							transform: translateY(-50%);
							left: 2rem;
							top: 50%;
							@include indicator();
						}

						// Lines
						&::before {
							content: '';
							top: 0;
							position: absolute;
							height: 100%;
							width: 1px;
							left: 2.6rem;
							background: $main-blue;
						}
					}

					// Make the lines on the first and last item half as tall.
					&.last,
					&.first {
						& a::before {
							height: 50%;
						}
					}

					// Nudge the line on the first item down half way.
					&.first {
						& a::before {
							top: 50%;
						}
					}

					// If an inventory item has been completed
					&.complete {
						> a {
							cursor: default;

							&::after {
								background: $main-yellow;
								box-shadow: inset 0px 0px 0px 2px rgba($white,1);
							}
						}
					}

					// If an inventory is not completed
					&.incomplete a:not([href^="http"]) {
						color: lighten($alt-blue, 15%);
						cursor: default;
					}
				}
			}
		}
	}
}

ul.results-subnav {
	display: flex;
	list-style: none;
	justify-content: center;
	background: $main-blue;
	margin: 0;
	@include grid-col-row();

	li {
		text-align: center;

		a {
			display: block;
			padding: 1rem;
			color: $white;
			transition: .125s;
		}

		&:hover {
			a {
				color: $medium-gray;
			}
		}

		&.active {
			a {
				color: $main-yellow;
			}
		}
	}
}

.feedback-button {
	background: $main-yellow;
	position: fixed;
	z-index: 1000;
	right: 2rem;
	top: $topbar-height;
	padding: .5rem 1.5rem;
	display: flex;
	align-items: center;
	transition: .125s;
	cursor: pointer;

	&:hover {
		background: $alt-yellow;
	}

	i {
		font-size: 1.25rem;
		position: relative;
		top: 2px;
		margin-right: .5rem;
	}

	span {
		font-size: 1rem;
	}
}

.no-js .top-bar {
  display: none;
}

@media screen and (min-width: 40em) {
  .no-js .top-bar {
    display: block;
  }

  .no-js .title-bar {
    display: none;
  }
}
