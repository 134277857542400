section.preference-assessment {
	header {
		margin-bottom: 3rem;
	}

	section.confirmation {
		background: $off-white;

		h3 {
			margin: 3rem 0 1rem 0;
		}

		.button {
			margin-top: 2rem;
		}

		@include breakpoint(small only) {
			padding: 2rem 0;
		}
	}

	@include breakpoint(small only) {
		margin-top: 3rem;
	}
}

section.assessment-group {
	margin-bottom: 3.5rem;
	position: relative;

	&.disabled {
		opacity: .5;

		.disabled-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}

	.question-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.5rem;

		h3 span {
			margin-right: 1rem;
		}

		a[data-open] {
			margin-left: 1rem;

			i {
				color: $main-yellow;
				font: {
					size: 2rem;
				}
			}
		}
	}

	.scale {
		margin-bottom: 1rem;

		header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1rem;
			font-weight: 100;
			max-width: rem-calc(200);
		}

		.bubble-group {
			margin-bottom: 1.25rem;
		}

		.bubbles {
			display: flex;
			justify-content: space-between;
			max-width: rem-calc(200);

			> .row {
				@include breakpoint(small only) {
					.column {
						&:first-of-type {
							order: 1;
						}
					}
				}
			}

			.bubble {
				background: $light-gray;
				width: 1.75rem;
				height: 1.75rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100%;
				cursor: pointer;

				&:hover,
				&.active {
					background: $main-yellow;
				}

				&.active {
					animation: ripple .5s 1;
				}
			}
		}

		.scale-label {
			&.invalid {
				color: tomato;
				font-weight: bold;

			}
		}
	}
}
