main.auth {
	display: flex;
	color: $white;
	min-height: 100vh;
	background: $main-blue;
}

section.form-area {
	flex: 1 1 60%;
	position: relative;
	z-index: 2;
	box-shadow: 5px 0px 20px 0px rgba(0,0,0,0.75);

	img {
		height: rem-calc(86);
		margin-bottom: 1rem;

		@include breakpoint(medium) {
			margin-bottom: 2rem;
		}
	}

	> .row {
		height: 100vh;
		align-items: center;
	}

	.form-container {
		max-width: 430px;
		width: 100%;
		float: none;
		margin: 0 auto;


		h3 {
			margin: 0;
		}

		.options {
			margin-top: .5rem;
			p {
				margin: 0;
			}

			a {
				color: $white;
				font-size: rem-calc(14);

				&:hover {
					color: $main-yellow;
				}
			}
		}

		.access-code-message {
			margin-top: 2rem;

			hr {
				max-width: 200px;
				margin: .5rem auto;
			}

			p.light {
				font-weight: 100;
				font-size: rem-calc(14);

			}
		}
	}
}

aside.image-area {
	display: none;
	position: relative;
	z-index: 1;

	@include breakpoint(medium) {
		flex: 1 1 40%;
		display: block;
		background: {
			image: url('../../img/auth/auth-image.jpg');
			position: center center;
			size: cover;
		}
	}
}
