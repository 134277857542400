/* *
   * Button Group
 */
.wsp-buttons {
  margin-top: 3rem;
  display: flex;
  align-items: center;

  @include breakpoint(small only) {
    flex-direction: column;

    .button {
      width: 200px;
      margin: 1rem 0;
    }
  }

  &.no-margin {
    margin-top: 0;
  }

  @include breakpoint(medium) {
    .button {
      margin: 0 .5rem;
      &:first-child {
        margin: 0 .5rem 0 0;
      }

      &:last-child {
        margin: 0 0 0 .5rem;
      }
    }
  }


  .wsp-indicators {
    list-style: none;
    margin: 0;
    font-size: 0;
    padding: 0 .5rem;

    li {
      @include indicator();
      display: inline-block;
      margin: 0 .5rem;
      position: relative;
      transition: .125s;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
      }

      &:hover {
        background: $light-gray;
        box-shadow: inset 0px 0px 0px 2px rgba($white,1);
      }

      &.active {
        background: $main-yellow;
        box-shadow: inset 0px 0px 0px 2px rgba($white,1);
      }

      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: $main-blue;
          right: -100%;
          top: 50%;
        }
      }
    }
  }
}

/* *
   * Help Modal/Info Buttons
 */
a.info-modal,
p.info-alert {
  font-size: rem-calc(20);
  font-weight: normal;
  position: relative;
  padding-left: 3rem;
  padding: .2rem 0 .2rem 3rem;
  display: inline-block;

  i {
    position: absolute;
    top: -1px;
    left: 0;
    font: {
      size: 2rem;
    }
  }
}

a.info-modal {
  i {
    color: $main-yellow;
  }

  &:hover {
    color: $alt-blue;
  }
}
