$icomoon-font-path: "../fonts" !default;

$wsp-info: "\e919";
$wsp-bolt: "\e900";
$wsp-bubble: "\e901";
$wsp-chevron-down: "\e902";
$wsp-chevron-left: "\e903";
$wsp-chevron-right: "\e904";
$wsp-chevron-up: "\e905";
$wsp-cog: "\e906";
$wsp-docs: "\e907";
$wsp-gear: "\e908";
$wsp-graph: "\e909";
$wsp-help: "\e90a";
$wsp-home: "\e90b";
$wsp-lock: "\e90c";
$wsp-message: "\e90d";
$wsp-phone: "\e90e";
$wsp-playbook: "\e90f";
$wsp-plus: "\e910";
$wsp-print: "\e911";
$wsp-refresh: "\e912";
$wsp-report: "\e913";
$wsp-sq-dash: "\e914";
$wsp-sq-line: "\e915";
$wsp-star: "\e916";
$wsp-stopwatch: "\e917";
$wsp-user: "\e918";

@font-face {
	font-family: 'McFletcher-WSP';
	src:
		url('#{$icomoon-font-path}/mcfletcher-wsp.ttf?srnkpi') format('truetype'),
		url('#{$icomoon-font-path}/mcfletcher-wsp.woff?srnkpi') format('woff'),
		url('#{$icomoon-font-path}/mcfletcher-wsp.svg?srnkpi#mcfletcher-wsp') format('svg');
	font-weight: normal;
	font-style: normal;
}

i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'McFletcher-WSP' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.wsp-info {
	&:before {
		content: $wsp-info;
	}
}
.wsp-bolt {
	&:before {
		content: $wsp-bolt;
	}
}
.wsp-bubble {
	&:before {
		content: $wsp-bubble;
	}
}
.wsp-chevron-down {
	&:before {
		content: $wsp-chevron-down;
	}
}
.wsp-chevron-left {
	&:before {
		content: $wsp-chevron-left;
	}
}
.wsp-chevron-right {
	&:before {
		content: $wsp-chevron-right;
	}
}
.wsp-chevron-up {
	&:before {
		content: $wsp-chevron-up;
	}
}
.wsp-cog {
	&:before {
		content: $wsp-cog;
	}
}
.wsp-docs {
	&:before {
		content: $wsp-docs;
	}
}
.wsp-gear {
	&:before {
		content: $wsp-gear;
	}
}
.wsp-graph {
	&:before {
		content: $wsp-graph;
	}
}
.wsp-help {
	&:before {
		content: $wsp-help;
	}
}
.wsp-home {
	&:before {
		content: $wsp-home;
	}
}
.wsp-lock {
	&:before {
		content: $wsp-lock;
	}
}
.wsp-message {
	&:before {
		content: $wsp-message;
	}
}
.wsp-phone {
	&:before {
		content: $wsp-phone;
	}
}
.wsp-playbook {
	&:before {
		content: $wsp-playbook;
	}
}
.wsp-plus {
	&:before {
		content: $wsp-plus;
	}
}
.wsp-print {
	&:before {
		content: $wsp-print;
	}
}
.wsp-refresh {
	&:before {
		content: $wsp-refresh;
	}
}
.wsp-report {
	&:before {
		content: $wsp-report;
	}
}
.wsp-sq-dash {
	&:before {
		content: $wsp-sq-dash;
	}
}
.wsp-sq-line {
	&:before {
		content: $wsp-sq-line;
	}
}
.wsp-star {
	&:before {
		content: $wsp-star;
	}
}
.wsp-stopwatch {
	&:before {
		content: $wsp-stopwatch;
	}
}
.wsp-user {
	&:before {
		content: $wsp-user;
	}
}
