section.home {
  .side-image {
    background-image: url(../img/app/home-intro.jpg);
  }
}

section.inventory {
  .side-image {
    background-image: url(../img/app/inventory-intro.jpg);
  }

  .action {
    @include breakpoint(small only) {
      margin-top: 2rem;
    }
    h3 {
      margin-bottom: 2rem;
    }
  }

  .info-modal {
    margin-top: 1.5rem;
  }
}

section.wsp-intro {
  .image-background {
    padding-bottom: 15rem;
    background: {
      color: $off-white;
      size: cover;
      position: center center;
    }
  }
}

section.about-you {
  form {
    margin-top: 2rem;
  }
}

section.position-actual-intro {
  h3 {
    margin-bottom: 1.5rem;
  }

  .banner-image {
    background-image: url(../img/app/position-actual-intro.jpg);
  }

  .info-modal {
    margin-top: 1.5rem;
  }
}

section.about-your-work {
  h3 {
    margin-bottom: 2rem;
  }

  .banner-image {
    background-image: url(../img/app/position-actual-intro.jpg);
  }
}
