.alert-box {
	border-width: 1px;
	border-style: solid;
	padding: 1rem;
	margin: 1.5rem 0;

	&.error {
		border-color: tomato;
		color: tomato;
		background: rgba(tomato, .3);
	}

	&.success {
		border-color: yellowgreen;
		color: $white;
		background: rgba(yellowgreen, .3);
	}
}
