/* *
   * Variables
 */
$topbar-height: rem-calc(86);
$sidebar-width: rem-calc(223);
$footer-height: rem-calc(42);

/* *
   * Global Styles
 */
main.app {
	display: flex;
	@include breakpoint(medium) {
		padding-bottom: $footer-height;
		padding-top: $topbar-height;
	}
}

@each $heading in 1, 2, 3, 4, 5, 6 {
  .margin-top-#{$heading} {
    margin-top: #{$heading}rem !important;
  }

	.margin-bottom-#{$heading} {
    margin-bottom: #{$heading}rem !important;
  }
}

h1, h2, h3, h4, h5, h6 {
	& sup {
		font-size: 50%;
	}
}

&.text-padded {
	padding: 2rem 0;
}

section.view {
	width: 100%;
	min-height: 100vh;
	@include breakpoint(medium) {
		min-height: calc(100vh - #{$topbar-height} - #{$footer-height});
		padding-left: $sidebar-width;
	}

	p {
		font-size: rem-calc(16);
		font-weight: 100;

		b {
			font-weight: 500;
		}
	}

	.banner-image {
    height: rem-calc(315);
    background: {
      color: $off-white;
      size: cover;
      position: center center;
    }
  }

	/* *
	   * Intro Section
	 */
	section.introduction {
		background: $off-white;
		position: relative;
		padding: 2rem 0;

		a.button {
			margin-top: 1.5rem;
			margin-bottom: 0;
		}

		.side-image {
			background: {
				size: cover;
				position: center center;
			}
		}

		@include breakpoint(medium) {
			padding: 0 0 0 2rem;

			.page-content {
				padding: 2rem 2rem 2rem 0;
			}

			.image {
				padding: 0;
			}

			ul,ol {
				margin-top: 1rem;
			}
		}

		@include breakpoint(large) {
			padding: 0 0 0 3rem;

			.page-content {
				padding: 3rem 3rem 3rem 0;
			}
		}
	}

	/* *
	   * Section Padding
	 */
	.view-content {
		padding: 2rem 0;
		@include breakpoint(medium) {
			padding: 2rem;
		}

		@include breakpoint(large) {
			padding: 3rem;
		}
	}

	.view-heading {
		background: $off-white;
		padding: 1rem 0;

		@include breakpoint(medium) {
			padding: 2rem 3rem;
		}

		h5 {
			margin: 0;
		}

		i {
			font-size: 2.5rem;
			margin-right: .5rem;
		}

		.heading-content {
			display: flex;
			align-items: center;
		}
	}

	.offwhite-bg {
	  background: $off-white;
	}

	ol {
	  margin-top: 2rem;

	  li {
	    font-weight: 100;
	    margin-bottom: 1.25rem;

	    span {
	      display: block;
	      font-weight: 500;
	    }
	  }
	}
}

ul.chevrons {
	list-style: none;
	margin: 0;

	ul {
		list-style: none;
		margin-bottom: .5rem;
	}

	li {
		position: relative;
		padding-left: 1.25rem;
		font-weight: 100;

		&:not(:last-child) {
			margin-bottom: 1.25rem;
		}

		&::before {
			content: '\e904';
			font-family: 'mcfletcher-wsp';
			font-size: .875rem;
			position: absolute;
			left: 0;
			top: 2px;
		}

		span {
			display: block;
			font-weight: 500;
		}
	}
}

ul.bubbles {
	margin: 0;

	li {
		display: flex;
		align-items: center;
		margin: 1.5rem 0;

		.bubble {
			text-align: center;
			line-height: 3rem;
			font-size: 1.5rem;
			margin-right: 1rem;

			.inner {
				width: 3rem;
				height: 3rem;
				background: $main-yellow;
				border-radius: 3rem;
			}
		}

		.description {
			p {
				margin-bottom: 0;
				font-size: 1.25rem;
				font-weight: 500;
			}

			small {
				font-size: 1rem;
			}
		}
	}
}

footer {
	background: $main-blue;
	color: $white;
	font-size: .75rem;
	padding: 1rem;
	display: flex;
	align-items: center;
	text-align: center;

	.footer.menu {
		display: block;

		li {
			line-height: 1rem;
			display: flex;
			justify-content: center;

			@include breakpoint(small only) {
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
		}

		a {
			color: $white;
			padding: 0;

			&:hover {
				color: $main-yellow;
			}
		}
	}

	@include breakpoint(medium) {
		padding: 0 2rem;
		height: $footer-height;
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 999;
		justify-content: flex-end;

		.footer.menu {
			display: flex;
			justify-content: flex-end;

			li {
				margin-bottom: 0;
				&:not(:last-child) {
					margin-right: 1rem;
				}
			}
		}
	}
}

.text-center hr.mini {
	margin: .75rem auto;
}

hr.mini {
	max-width: 4rem;
	margin: .75rem 0;
	border-color: $main-blue;
}

ul.arrows {
	list-style: none;

	li {
		position: relative;
		margin-bottom: .25rem;

		&::before {
			font-family: 'McFletcher-WSP';
			content: "\e904";
			position: absolute;
			left: -1rem;
			font-weight: bold;
			font-size: .875rem;
		}
	}
}
