@mixin button($color, $hover-color, $background, $hover-background) {
	font-size: 1rem;
	color: $color;
	padding: .5rem 2.25rem;
	position: relative;
	transition: .125s;
	margin-bottom: 0;
	animation-duration: .45s;
	font-family: $body-font-family;

	&:hover,
	&:active {
		color: $hover-color;
		background: $hover-background;
	}

	&:focus {
		color: $color;
	}

	&.icon {

		i.wsp {
			position: absolute;
			top: -1px;
			opacity: 0;
			transition: .125s;
			padding: .625rem 0;
			font-size: .875rem;
		}

		&.right {
			i.wsp {
				right: 1.5rem;
			}
		}

		&.left {
			i.wsp {
				left: 1.5rem;
			}
		}

		&:hover {
			i.wsp {
				opacity: 1;
			}

			&.right {
				i.wsp {
					right: .875rem;
				}
			}

			&.left {
				i.wsp {
					left: .875rem;
				}
			}
		}
	}
}

.button {
	@include button($main-blue, $main-blue, $main-yellow, $alt-yellow);
}

@mixin indicator() {
	width: 1.25rem;
	height: 1.25rem;
	border: 1px solid $main-blue;
	border-radius: 100%;
	background: $white;
}

@keyframes ripple {
  from {
    box-shadow: 0px 0px 0px rgba(darken($main-yellow, 25%), 1);
  }
  to {
    box-shadow: 0px 0px 45px transparent;
  }
}
