section.reports {
  .banner-image {
    background-image: url(../img/app/reports-main.jpg);
  }
}

p.icon-title {
  display: flex;
  align-items: center;

  img {
    height: 3rem;
  }

  span {
    font-size: 1.25rem;
    margin-left: 1rem;
    font-weight: normal;
  }
}

.button-block {
  text-align: center;
  padding: 2rem;
  background: $off-white;
  margin-top: 1rem;
}
