.reveal {
	width: 100%;
	max-width: rem-calc(713);
	padding: 0;

	button.close-button {
		background: $main-yellow;
		color: $main-blue;
		height: rem-calc(38);
		width:  rem-calc(38);
		top: 0;
		right: 0;

		&:hover {
			background: $alt-yellow;
		}
	}

	h2 {
		i.wsp {
			color: $main-yellow;
			margin-right: 1rem;
			font: {
				size: 90%;
				weight: bold;
			}
		}
	}

	header {
		&.image {
			height: 150px;
			background: {
				size: cover;
				position: center center;
			}
		}

		&.info {
			background: $main-yellow;
			padding: 1rem;
			@include breakpoint(medium) {
				padding: 2.5rem 6rem;
			}

			h2 {
				margin-bottom: 0;

				i {
					display: inline;
					font-size: inherit;
					margin-right: 1rem;

					&.wsp-bubble {
						position: relative;
						top: 7px;
					}
				}
			}

			i {
				display: block;
				font-size: 4rem;
				margin-bottom: 1rem;
			}
		}
	}

	section.content {
		&.gray-bg {
			background: lighten($light-gray, 11%);
		}

		padding: 1rem;
		@include breakpoint(medium) {
			padding: 2.5rem 6rem;
		}

		img {
			margin: 2rem 0;
		}

		p {

			a {
				font-weight: 500;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		div.contact-line {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;

			i {
				font-size: 2rem;
				margin-right: .75rem;

				&.wsp-message {
					font-size: 1.25rem;
				}
			}

			h3 {
				margin-bottom: 0;
			}
		}

		ul.chevrons {
			li {
				margin-bottom: 0;
			}
		}
	}
}

.reveal-overlay {
	.reveal {
		display: block !important;
	}
}
