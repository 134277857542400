section.strategies {
  .heading {
    p {
      margin-bottom: 0;
    }
  }

  &.take-action {
    .banner-image {
      background-image: url(../img/app/strategies-take-action.jpg);
    }
  }

  &.resources {
    .banner-image {
      background-image: url(../img/app/strategies-resources.jpg);
    }
  }
}
