form.auth-form {
	margin-top: 3rem;

	.form-input {
		position: relative;

		input {
			height: 3.125rem;
			margin: 0;
			padding-left: 4rem;

			&.is-invalid-input {
				background-color: $white;
				border: 2px solid map-get($foundation-palette, alert);
				margin-bottom: 1rem;
			}
		}

		i.wsp {
			position: absolute;
			color: $main-yellow;
			left: 1rem;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.75rem;
			font-weight: bold;
			width: 28px;
		}

		&:not(:first-of-type) {
			input {
				border-top: 2px solid $medium-gray;
			}
		}
	}

	button.button {
		margin-top: .25rem;
		padding: 1rem 1.75rem;
		display: block;
		width: 100%;
		text-align: left;

		i {
			position: relative;
			left: .5rem;
			font-size: .875rem;
		}
	}
}

form.wsp-form {
	padding-top: 1.25rem;

	.form-input {
		position: relative;
		padding-bottom: 1rem;

		@include breakpoint(small only) {
			display: flex;
			flex-direction: column;

			label {
				order: 1;
			}

			#{text-inputs()},
			textarea,
			select {
				order: 2;
			}

		}

		#{text-inputs()},
	  textarea,
	  select {
			color: $main-blue;
			transition: .125s;
			border-bottom: 2px solid $medium-gray;
			position: relative;
			z-index: 3;
			background: transparent;
			//cursor: pointer;

			&:focus,
			&.dirty {
				border-bottom: 2px solid $main-blue;
				background: darken($body-background, 3%);
				padding: 0 .5rem;
				//cursor: text;

				~ label {
					top: rem-calc(-23);
				}
			}
		}

		textarea {
			padding-top: .5rem;
			padding-bottom: 0;

			&:focus,
			&.dirty {
				padding-top: .5rem;
				padding-bottom: 0;
			}
		}

		label {
			color: $main-blue;
			font-size: .875rem;
			cursor: pointer;

			small {
				font-size: .75rem;
				font-weight: 100;
			}

			@include breakpoint(medium) {
				position: absolute;
				top: .375rem;
				z-index: 2;
				transition: .125s;
				cursor: text;
			}
		}
	}
}

ul.star-rating {
	list-style: none;
	display: flex;
	margin: 0;
	padding-top: 2rem;
	padding-bottom: 1rem;
	color: $medium-gray;
	font-size: 2rem;

	li {
		position: relative;
		cursor: pointer;

		&:not(:last-child) {
			padding-right: .5rem;
		}

		&::before {
			font-family: 'McFletcher-WSP';
			content: '\e916';
		}

		&:hover,
		&.active,
		&.selected {
			color: $main-yellow;
		}
	}
}
