section.results-main {
  display: flex;
  flex-direction: column;
  height: 100%;

  .banner-image {
    flex-grow: 1;
    background-image: url(../img/app/results-main.jpg);
  }
}

section.results-quick {
  .banner-image {
    background-image: url(../img/app/results-quick2.jpg);
  }
}

section.results-quick,
section.results-full {
  .graph-container {
		position: relative;
    width: 100%;
    max-width: 452px;
    margin: 0 auto;

    @include breakpoint(medium) {
      &.with-arrows {
        margin-top: 6rem;
        padding-top: 1rem;

        .arrow,
        .graph-label {
          &[data-left],
          &[data-right] {
            top: 42%;
          }
        }
      }
    }

    &.small-graph {
      .small-image {
        margin-top: 6rem;
        margin-bottom: 1rem;
      }
    }

		.graph-image {
      position: relative;
      width: 100%;
      height: 0px;
      margin: 0 auto;
      padding-bottom: 100%;
      background: {
        size: contain;
        repeat: no-repeat;
        position: center center;
      }

      @include breakpoint(medium) {
        width: 100%;
      }
    }

		.arrow,
    .graph-label {
			position: relative;
			top: .5rem;
			cursor: pointer;
			margin-bottom: 1rem;
			transition: .125s;
      position: absolute;
			margin-bottom: 0;

      @include breakpoint(small only) {
        padding: .5rem;

        &[data-first],
        &[data-second],
        &[data-third] {
          top: -.875rem;
          display: none;
        }

				// &[data-first] {
				// 	left: 11.5%;
				// }

				// &[data-second] {
				// 	left: 39.5%;
				// }

				// &[data-third] {
				// 	left: 66.5%;
				// }

				&[data-left],
				&[data-right] {
					top: 43%;
          display: none;
				}

				// &[data-left] {
				// 	left: -5px;
				// }

				// &[data-right] {
				// 	right: -5px;
				// }
			}

      @include breakpoint(medium) {
        padding: .875rem;

        &[data-first],
        &[data-second],
        &[data-third] {
          top: -3.75rem;
        }

        &[data-first] {
          left: 8.5%;
        }

        &[data-second] {
          left: 40%;
        }

        &[data-third] {
          left: 69.5%;
        }

        &[data-left],
        &[data-right] {
          top: 43%;
        }

        &[data-left] {
          left: -110px;
        }

        &[data-right] {
          right: -110px;
        }
      }

			p {
				margin-bottom: 0;
				line-height: 1rem;
				font-size: 1rem;
				font-weight: normal;

				span {
					line-height: 1rem;
					display: none;
					font-size: .875rem;

          @include breakpoint(medium) {
            display: block;
          }
				}
			}
		}

    .arrow {
      background: $main-yellow;

      @include breakpoint(small) {
				position: absolute;
				margin-bottom: 0;

				&::before,
				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					transition: .125s;
				}

				&.on-right {
					&::before {
            top: 50%;
            transform: translateY(-50%);
            right: calc(-100% - 1rem);
						@include css-triangle(1rem, $main-yellow, right);
					}
				}

        &.on-left {
					&::before {
            top: 50%;
            transform: translateY(-50%);
            left: calc(-100% - 1rem);
						@include css-triangle(1rem, $main-yellow, left);
					}
				}

				&.on-bottom {
					&::after {
            bottom: -1rem;
						@include css-triangle(1rem, $main-yellow, down);
					}
				}
      }

      &:hover {
        background: $alt-yellow;

        &::before {
          border-color: transparent $alt-yellow transparent;
        }

        &::after {
          border-color: $alt-yellow transparent transparent;
        }
      }
    }
	}

  .metric-descriptions {
		margin-top: 1rem;
    width: 100%;
    max-width: 452px;
    margin: 0 auto;

		p {
			font-weight: normal;
			font-size: 1.25rem;
      line-height: 1.25rem;
      margin-bottom: 0;
		}
	}

	.score-table {
    width: 100%;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}

		&.dark {
			ul {
				background: $medium-gray;
			}
		}

		h5 {
			margin-bottom: 0;
		}

		small {
			margin-bottom: .75rem;
			display: inline-block;
		}

		ul {
			margin: 0;
			list-style: none;
			display: flex;
			justify-content: space-around;
			background: $white;

			&:not(:first-of-type) {
				border-top: 1px solid $main-blue;
			}

			li {
				width: 100%;

				&:not(:last-child) {
					border-right: 1px solid $main-blue;
				}
			}
		}
	}

  .label-text {
    display: inline-block;
    background: $off-white;
    padding: .5rem;
    font-weight: normal;
    line-height: 1rem;
  }

  .stress-table {
    margin-bottom: 2rem;

    .column {

      .title {
        padding: 1.5rem;
      }

      .cell {
        background: $white;
        padding: .75rem;

        span {
          display: inline-block;
          padding: .75rem;
          transition: .125s;

          &.active {
            background: $main-yellow;
            padding: .75rem 1.5rem;
            cursor: pointer;

            &:hover {
              background: $alt-yellow;
            }
          }
        }
      }
    }
  }
}

h5.hyphens {
  display: inline-block;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: rem-calc(35);
    top: 50%;
    border-bottom: 1px solid $main-blue;
  }

  &::before {
    left: rem-calc(-45);
  }

  &::after {
    right: rem-calc(-45);
  }
}

section.results-full {
  &.results-main {
    .banner-image {
      background-image: url(../img/app/results-full.jpg);
    }
  }

  &.pref-step-4 {
    .banner-image {
      background-image: url(../img/app/results-pref4.jpg);
    }
  }

  &.pref-step-5 {
    .banner-image {
      background-image: url(../img/app/results-pref5.jpg);
    }

    .results-table {
      margin-bottom: 3rem;
    }
  }

  &.actual-step-2 {
    .banner-image {
      background-image: url(../img/app/results-actual2.jpg);
    }
  }

  &.compare-step-2 {
    .banner-image {
      background-image: url(../img/app/results-compare2.jpg);
    }
  }
}

.view-image {
  position: relative;

  &.image-left {
    .image {
      left: 0;
    }
  }

  &.image-right {
    .image {
      right: 0;
    }
  }

  .image {
    min-height: 200px;
    background: {
      size: cover;
      position: center center;
    }

    @include breakpoint(medium) {
      position: absolute;
      width: 25%;
    }

    @include breakpoint(large) {
      width: 33.333%;
    }

    &.half-width {
      @include breakpoint(medium) {
        position: absolute;
        width: 33.3333%;
      }

      @include breakpoint(large) {
        width: 41.6667%;
      }
    }
  }
}

.wsp-table {
  margin-top: 2rem;
  margin-bottom: 3rem;
  border-collapse: collapse;

  thead {
    background: $main-blue;
    color: $white;

    td {
      font-weight: normal;
    }
  }

  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $main-blue;
      }

      td {
        background: $off-white;
        padding: 1rem;
        vertical-align: top;
        font-weight: 100;
        font-size: .875rem;

        &.left {
          background: $light-gray;
          font-weight: normal;
        }

        &:not(:first-child) {
          border-right: 1px solid $main-blue;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

.results-table {
  background: $off-white;
  text-align: left;

  .item {
    padding: 1rem 2rem;
    display: flex;

    @include breakpoint(small only) {
      flex-direction: column;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $main-blue;
    }

    p {
      margin-bottom: 0;
    }

    .left {
      font-weight: 500;
      margin-right: 1rem;
      @include breakpoint(medium) {
        min-width: 30%;
      }
    }

    .right {
      flex-grow: 1;
    }
  }

  &.vertical {
    display: flex;

    .column {
      padding-top: 1rem;

      &:not(:last-child) {
        border-right: 1px solid $main-blue;
      }
    }
  }
}
