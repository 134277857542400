[data-accordion] {
  background: transparent;
  margin-bottom: 3rem;

  .accordion-item {
    margin-bottom: .25rem;

    &:hover,
    &.is-active {
      .accordion-title {
        color: $white;
      }
    }
  }

  .accordion-title {
    font-size: 1rem;
    background: $main-blue;

    &::before {
      font-size: 2rem;
      top: 50%;
      margin: 0;
      transform: translateY(-50%);
      color: $main-yellow;
    }

    span.bold {
      font-weight: bold;
    }
  }

  .accordion-content {
    @include breakpoint(medium) {
      padding: 2rem;
    }

    @include breakpoint(large) {
      padding: 2rem 4rem;
    }

    a.link {
      margin-bottom: 1rem;
      display: inline-block;
      position: relative;
      transition: .125s;

      &:hover {
        color: $medium-gray;
      }

      &::after {
        content: $wsp-chevron-right;
        font-family: 'McFletcher-WSP';
        position: absolute;
        right: -1rem;
        font-size: .875rem;
        top: rem-calc(2);
        font-weight: bold;
      }
    }
  }

  [data-accordion] {
    margin-bottom: 0;
    margin-top: 1rem;

    .accordion-item {
      &:hover,
      &.is-active {
        .accordion-title {
          color: $main-blue;
        }
      }
    }

    .accordion-title {
      font-size: 1.25rem;
      background: transparent;
      color: $main-blue !important;
      padding: 1.25rem 0;
      border-bottom: 1px solid $main-blue;

      &::before {
        color: $main-blue;
      }
    }

    .accordion-content {
      padding: 1.25rem 0;

      ul.chevrons {
        font-size: .875rem;
        margin-bottom: 2rem;
      }
    }
  }
}
